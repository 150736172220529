
import BButton from 'buetify/lib/components/button/BButton';
import BBox from 'buetify/lib/components/layout/box/BBox';
import BContainer from 'buetify/lib/components/layout/container/BContainer';
import BHero from 'buetify/lib/components/layout/hero/BHero';
import BHeroBody from 'buetify/lib/components/layout/hero/BHeroBody';
import BSubtitle from 'buetify/lib/components/title/BSubtitle';
import { defineComponent } from 'vue';
import BuetifyHomeNavbar from '../../components/buetifyNavbar/BuetifyHomeNavbar.vue';

export default defineComponent({
	name: 'home',
	components: {
		BBox,
		BuetifyHomeNavbar,
		BHero,
		BHeroBody,
		BContainer,
		BButton,
		BSubtitle
	}
});
