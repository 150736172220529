
import { BNavbarMenu, BTitle } from 'buetify/lib/components';
import BNavbar from 'buetify/lib/components/navbar/BNavbar';
import BNavbarBrand from 'buetify/lib/components/navbar/BNavbarBrand';
import BNavbarBurger from 'buetify/lib/components/navbar/BNavbarBurger';
import BNavbarEnd from 'buetify/lib/components/navbar/BNavbarEnd';
import BNavbarItem from 'buetify/lib/components/navbar/BNavbarItem';
import { useToggle } from 'buetify/lib/composables';
import { defineComponent } from 'vue';
import GithubIcon from '../icons/GithubIcon';

export default defineComponent({
	name: 'buetify-navbar',
	components: {
		BNavbar,
		BNavbarItem,
		BNavbarBrand,
		BNavbarBurger,
		BNavbarMenu,
		BNavbarEnd,
		GithubIcon,
		BTitle
	},
	setup() {
		return useToggle({ isActive: false, hasPopup: true }, 'isActive');
	}
});
